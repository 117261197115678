import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import HTMLContent from '../components/Content'
import Fotos from '../components/Fotos';
import TVBHeader from '../components/TVBHeader'
import TVBTimelineItem from '../components/TVBTimelineItem'

// import overOnsBackGround from '../../static/img/over-ons-background.jpg'
import overOnsBackGround2 from '../../static/img/over-ons-background2.jpg'
// import homeBackGround from '../../static/img/home-background.jpg'

import test from '../../static/img/test_tom.png'
import test2 from '../../static/img/test_tom_2.png'
// import test3 from '../../static/img/test_tom_3.png'
import test32 from '../../static/img/test_tom_3_2.png'
import test4 from '../../static/img/test_tom_4.png'
import test5 from '../../static/img/test_tom_5.png'
import test6 from '../../static/img/test_tom_6.png'
import luchtfoto1 from '../../static/img/luchtfoto.jpg'
import luchtfoto2 from '../../static/img/luchtfoto2.jpg'
import luchtfoto3 from '../../static/img/luchtfoto3.jpg'
import tussenplanting from '../../static/img/tussenplanting.jpg'
// import lichten from '../../static/img/lichten.jpg'
import laatsteversie from '../../static/img/laatsteversie.jpg'
// import foto14 from '../../static/img/foto-page/foto-14.jpg'


export const OverOnsPageTemplate = ({contentComponent}) => {

    return (
        <div className="tvb-over-ons">
            <TVBHeader title="Over ons" background={overOnsBackGround2} isMedium={true}/>

            <section className="section tvb-quote">
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <h1>Hard werk om een mooi en kwaliteitsvol product af te leveren.</h1>
                        </div>
                        <div className="column">
                            <p>
                                Tuinbouwbedrijf Van Bulck uit Putte behoort tot één van de
                                grootste telers van vleestomaten in België. Dankzij het innovatief
                                ondernemerschap van zaakvoerders Johan Van Bulck en Nancy hugo groeide
                                het glastuinbedrijf uit tot 6 hectare aan serres en 30 medewerkers.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section tvb-images">
              <Fotos urls={[luchtfoto1, tussenplanting, luchtfoto2, laatsteversie, luchtfoto3]} size="is-one-fifth"/>
            </section>
            <section className="section tvb-historiek">
                <div className="container">
                    <h1>
                        Mijlpalen in de geschiedenis van ons bedrijf...
                    </h1>
                    <div style={{padding: '100px 0'}}>

                        <TVBTimelineItem reverse={false} image={test} date={1993} title="Start" body="In februari 1993 werd de teelt van vleestomaten, door Johan Van Bulck en Nancy hugo, opgestart in een serre van één hectare."/>
                        <TVBTimelineItem reverse={true} image={test2} date={1994} title="Eerste uitbreiding" body="Na echter een periode van één jaar werd hier al een oppervlakte van 0.6 hectare aan toegevoegd, waarna continue verbeteringen elkaar pijlsnel opvolgden."/>
                        <TVBTimelineItem reverse={false} image={test4} date={2007} title="Eerste WKK" body="Met de installatie van de eerste warmtekrachtkoppeling in 2007 werd de aanzet gegeven tot één van de eerste diepgaande en milieuvriendelijke investeringen voor het bedrijf van Johan en Nancy."/>
                        <TVBTimelineItem reverse={true} image={test32} date={2011} title="Verdere uitbreidingen" body="In 2011 volgde een uitbreiding in oppervlakte van 1.6 naar 4.6 hectare waarbij ook een ultramodern sorteertoestel, zelfrijdende oogstkarren, een nieuwe sorteer- en verpakkingsloods en een nieuw waterbassin werden geïnstalleerd. "/>
                        <TVBTimelineItem reverse={false} image={test5} date={2015} title="Natriumdamplampen" body="Door het aanbrengen van Natriumdamplampen in 2015 sloeg Tuinbouwbedrijf Van Bulck volledig de milieubewuste weg in."/>
                        <TVBTimelineItem reverse={true} image={test6} date={2017} isLastItem={true} title="Nu" body="De laatste uitbreiding dateert van 2017 waarbij de oppervlakte van de teeltoppervlakte verder werd vergroot tot zes hectare. Momenteel stelt het bedrijf vijftien vaste medewerkers en vijftien seizoensarbeiders te werk."/>

                    </div>

                </div>
            </section>
        </div>
    )
}

OverOnsPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const OverOnsPage = ({data}) => {
    return (
        <Layout>
            <Helmet>
                <title>Over ons</title>
                <meta name="description" content="Kom meer te weten over ons en de evolutie van het bedrijf" />
            </Helmet>
            <OverOnsPageTemplate contentComponent={HTMLContent} />
        </Layout>
    )
}

OverOnsPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default OverOnsPage

export const overOnsPageQuery = graphql`
query OverOnsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
