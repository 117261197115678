import React from 'react'

import tomatenLijn from '../../static/img/tomaten-lijn.png'

const TVBTimelineItem = ({image, date, title, body, reverse, isLastItem}) => (
    <div className="columns timeline-item">
    { reverse ? '' : <TVBTimelinePic image={image} reverse={reverse} isLastItem={isLastItem} date={date}/> }
    <div className="column" >
        <h2>{title}</h2>
        <p>{body}</p>
    </div>
    { reverse ? <TVBTimelinePic image={image} reverse={reverse} isLastItem={isLastItem} date={date}/>: ''}
  </div>
);
export default TVBTimelineItem;


const TVBTimelinePic = ({image, date, reverse, isLastItem}) => (
  <div className="column" style={{position: 'relative'}}>
    <img src={image} alt=""/>
    <h2 className="timeline-item-date">{date}</h2>
    { isLastItem ? '' : 
      <img src={tomatenLijn} className="timeline-item-image" alt="" style={{position: 'absolute', top: '450px', left: reverse ? '-240px' : '240px', zIndex: -1, transform: reverse ? 'scale(-1, 1)' : ''}} />
    }
  </div>
);


// TVBFooter.propTypes = {
//     title: PropTypes.string.isRequired,
//     subtitle: PropTypes.string,
//     background: PropTypes.string.isRequired,
// }